import React from 'react'
import { graphql } from 'gatsby'
import { parseSeo } from '../helpers/seo'
import { WpBlocksGenerator } from '../helpers/wpBlocksGenerator'
import Layout from '../views/Layout'
import SEO from '../views/Seo'
import { Breadcrumbs } from '../components/Breadcrumbs'
import { PageContent } from '../components/PageContent'
import { Content } from '../components/Content'
import { Heading } from '../components/Heading'
import { Container } from '../components/Container'

export default ({ data }) => {
  const faq = data.wordpress.faq
  const { blocks = [], seo, title } = faq

  return (
    <Layout>
      <SEO {...parseSeo(seo)} />
      <Breadcrumbs breadcrumbs={seo.breadcrumbs} />
      <PageContent>
        <Container>
          <Content>
            <Heading>{title}</Heading>
          </Content>
        </Container>

        <WpBlocksGenerator blocks={blocks} post={faq} />
      </PageContent>
    </Layout>
  )
}

export const query = graphql`
  query($id: ID!) {
    wordpress {
      faq(id: $id) {
        ...Single_Faq
      }
    }
  }
`
